import { Box, Button } from "@mui/material";
import React from "react";
import SubHeader from "../Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import TextField from '@mui/material/TextField';
import { Container } from "@mui/system";
import { Link } from "@mui/material";
import * as sr from "../Service/create";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import tophsvg from '../../assets/img/BG.svg'
// import tophsvg from '../BG.svg'
export const Newproject = () => {
  //text hover
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);
  const [isHover6, setIsHover6] = useState(false);
  //icons hover
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);
  const [card6, setCard6] = useState(false);







const [open, setOpen] = React.useState(false);
const [opts, setOpts] = React.useState([])

  // const history = useHistory();

  const [selectedIndex, setSelectedIndex] = React.useState(0);




  const [opend, setOpend] = React.useState(false);


  const [records, setRecords] = React.useState("");

  const [pName, setPname] = React.useState("");
  const [pOwner, setPowner] = React.useState("");
  const [pDesc, setPdesc] = React.useState("");
  const [pTestlead, setTestlead] = React.useState("");
  const [pTDesc, setTPTDesc] = React.useState("");


  const HandleAddProjectClick = () => {
    setOpen(true);
    var data = new Object()
    data["project_name"] = pName
    data["project_owner"] = pOwner
    data["project_description"] = pDesc
    data["test_lead"] = pTestlead
    data["project_test_description"] = pTDesc

    console.log("Add new project data: ", data);
    // var response = createProject(data)
    sr.createProject(setRecords, data);
    console.log("Call data", data);
  }

  const handleClose = () => {
    setOpen(false);
    //   history.push('/dashboard');
  }

  return (
    <>
      <SubHeader dropbutton />
<Box mt={10}> 
      <Box
        sx={{
          background: `url(${tophsvg})`,
          
          marginLeft: "2%",
          marginBottom: "1.5%",
          marginRight: "1.5%",
          height: "100%",
        }}
      >
        {/* <Box sx={{ paddingLeft: 20, paddingRight: 20 }}> */}

        <Box    sx={{  position: "fixed",marginTop:4, width:"94%",marginLeft:1}}>
          <AppBar
            position="static"
            sx={{
              
              borderRadius: 3,
              marginBottom: "0.5%",
              backgroundColor: "white",
            }}
          >
            <Toolbar>

              <Typography
                className='MuiCardHeader-title'
                component="div"
                sx={{ display: { xs: "none", align: 'left', sm: "block" }, color: "black" }}
                display="inline"

              >
                Project details
              </Typography>
              {/* <EditIcon /> */}

            </Toolbar>
          </AppBar>
        </Box>
<Box sx={{paddingTop:15, marginLeft:2}}>
        <Box >

          <TextField value={pName} onChange={(e) => { setPname(e.target.value) }} id="p_name" label="Project name" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
        </Box>

        <Box>

          <TextField value={pOwner} onChange={(e) => { setPowner(e.target.value) }} id="p_owner" label="Project owner" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
        </Box>
        <Box>

          <TextField value={pTestlead} onChange={(e) => { setTestlead(e.target.value) }} id="p_testlead" label="Test lead" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
        </Box>
        <Box>

          <TextField value={pDesc} onChange={(e) => { setPdesc(e.target.value) }} id="p_desc" label="Project description" variant="outlined" sx={{ 
    minWidth: 600, 
    marginBottom: 1.5, 
    marginTop: 1.5, 
  }} 
  multiline
  rows={4} 
  rowsMax={10} />
        </Box>
        <Box>

       
        <TextField value={pTDesc} onChange={(e) => { setTPTDesc(e.target.value) }} id="p_tdesc" label="Project test description" variant="outlined" sx={{ 
    minWidth: 600, 
    marginBottom: 1.5, 
    marginTop: 1.5, 
  }} 
  multiline
  rows={4} 
  rowsMax={10} />

</Box>

        <Button onClick={HandleAddProjectClick} size="large" variant="contained" sx={{
                        color: "white",
                        backgroundColor: "#d7a8df",
                        marginLeft: "auto",

                      }}>
          <Typography className='MuiCardHeader-title3' sx={{  color: "white" }}>Add project</Typography>
        </Button>
        <Box sx={{paddingTop:1}}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className='MuiCardHeader-title1' id="alert-dialog-title">{"Project added successfully"}</DialogTitle>
          <DialogContent paddingTop>
            <DialogContentText className='MuiCardHeader-title1' id="alert-dialog-description">
              Your project has been added successfully!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button href="/dashboard" onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
        {/* </Link> */}
        {/* </Link> */}

        </Box>


        </Box>
      </Box>
      </Box>
    </>
  );

};
