import React from "react";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Link,
  Stack,
  AppBar,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import { Link as Link1 } from "react-router-dom";




import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import logo from "../../../Testing/logo.png";
import logo2 from "../../../assets/img/sqword.png";
import logo1 from "../../../assets/img/Squirrel_logo_Final-01.png";

import SwitchAccessShortcutAddOutlinedIcon from '@mui/icons-material/SwitchAccessShortcutAddOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Menu from '@mui/material/Menu';
import EngineeringIcon from '@mui/icons-material/Engineering';

// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import tophsvg from '../../../assets/img/Top1.svg'
import ProfileSection from "../../../components/layout/squirrel/header/profileSection";

// The main Home component returned by this Module //
const SubHeader = ({
  title,
  dropbutton,
  btnUrl,
  backbutton,
  modal_flag = false,
  warning_flag = false,
  bf,
}) => {

  //app side bar 

  const [state, setState] = React.useState({

    Menu: false,


  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };



  // Material UI Classes title //
  const [open, setOpen] = React.useState(false);
  const [costcenter, setCostcenter] = React.useState(null);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleClick = (event) => {
    setOpen(true);
  };



  const costcentervalue = localStorage.getItem("session_costcenter")

  const default_option = [{ "label": "Loading...", "id": "-1" }]
  console.log(costcenter);
  return (


    <>
      {/* <Paper>
        <Grid
          
          sx={{ position: "fixed", top: 0, zIndex: 10, width: "100%",background: `url(${tophsvg})`, backgroundRepeat: 'repeat-x', backgroundSize: '100%', backgroundColor:"white",backgroundSize: 'cover' }}   >
          <Grid container>
            <Grid>
              {backbutton == true ? (
                warning_flag == true ? (
                  <Button sx={{ marginTop: "35%" }} onClick={bf}>
                    {<ArrowBackIcon variant="h6" sx={{ color: "white" }} />}
                  </Button>
                ) : (
                  <Button
                    component={Link1}
                    to={btnUrl}
                    sx={{ marginTop: "35%" }}
                  >
                    {<ArrowBackIcon variant="h6" sx={{ color: "white" }} />}
                  </Button>
                )
              ) : null}
              
            </Grid>
            <Grid sx={{ marginTop: ".5%"}}>
              {title && (
                <Typography variant="h6" color="white" fontWeight={700} sx={{marginTop:"20%"}}>
                  {title}
                </Typography>
              )}{" "}
            </Grid>
          </Grid>
          
          {dropbutton == true ? (
            <Toolbar>
              
              <Link href="/dashboard">

              <img
                      style={{height: 90, width: 90 , marginLeft:".2%"}}
                      src={logo}
                      alt="pic"
                      // className="image_wraper"
                      
                    />
                    </Link>
            </Toolbar>
          ) : null}
          
        </Grid>{" "}
      </Paper>{" "}
       */}

      <AppBar position="static " >

        <Toolbar sx={{ position: "fixed", top: 0, zIndex: 10, width: "100%", background: `url(${tophsvg})`, backgroundRepeat: 'repeat-x', backgroundSize: '40%', height: "90px" }}>






          <Box  >

            <Stack direction={'row'}>




              <Box sx={{ marginTop: 5 }}>

                <img

                  style={{ height: 40, width: 50, marginLeft: ".2%" }}

                  src={logo1}

                  alt="pic"

                  className="image_wraper" />

              </Box>

              <Box>

                <img

                  style={{ height: 12, width: 70, marginLeft: ".2%", marginTop:67 }}

                  src={logo2}

                  alt="pic"

                  className="image_wraper" />

              </Box>

            </Stack>




          </Box>





          {/* <Button sx={{marginLeft:"5%",marginTop:"3.5%"}} > */}







          {/* <Box sx={{ marginLeft: "auto" }}>

            <Button sx={{ height: "50px" }}>

              <ProfileSection  {...props} />

            </Button>

          </Box> */}








        </Toolbar>

      </AppBar>
    </>
  );
};

export default SubHeader;
