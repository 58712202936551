import React, {  } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import Login from "./components/pages/auth/Login";
import LoginLayout from "./components/layout/auth/LoginLayout";

import SquirrelLayout from "./components/layout/squirrel";
// import Example from "./components/pages/admin/region";
import { Dashboardtest } from "./Testing/screens/Dashboard";
import { Newproject } from "./Testing/screens/Newproject";
import { Newtestscenario } from "./Testing/screens/newtestscenario";
import { TestSc } from "./Testing/screens/Scenario2";
import { Testdetails } from "./Testing/screens/test_details";
import { X } from "./Testing/x";


const PrivateRoute = ({ isAuthenticated }) => {
  const location = useLocation();
  const authToken = localStorage.getItem("token");
  console.log("1233ds", isAuthenticated);
  return isAuthenticated || authToken ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/",
        state: { from: location },
      }}
    />
  );
};

function Urls(props) {
  // const location = useLocation();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <LoginLayout>
            <Login {...props} />
          </LoginLayout>
        }
      />

      <Route element={<PrivateRoute isAuthenticated={props.isAuthenticated} />}>
        <Route
          exact
          path="/Dashboard"
          element={
            // <SquirrelLayout>
              <Dashboardtest {...props} />
            // </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/Newproject"
          element={
            // <SquirrelLayout>
              <Newproject {...props} />
            // </SquirrelLayout>
          }
        />

<Route
          exact
          path="/X"
          element={
            // <SquirrelLayout>
              <X {...props} />
            // </SquirrelLayout>
          }
        />



        <Route
          exact
          path="/newtestscenario"
          element={
            // <SquirrelLayout>
              <Newtestscenario {...props} />
            // </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/scenario2"
          element={
            // <SquirrelLayout>
              <TestSc {...props} />
            // </SquirrelLayout>
          }
        />
        <Route
          exact
          path="/test_details"
          element={
            // <SquirrelLayout>
              <Testdetails {...props} />
            // </SquirrelLayout>
          }
        />
       
      </Route>
    </Routes>
  );
}

export default Urls;
