import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { Box, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import SubHeader from "../Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as as from "../Service/create";
import * as asx from "../Service/update";

import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import axios from "axios";
import Controls from '../../components/controls/Controls';
// import Popup from '../Components/ui/Popup';
import useTable from "../../components/ui/useTable";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import TestForm from "./TestForm";
// import Notification from "../Components/ui/Notifications";
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import styled from "@emotion/styled";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DatePicker from "react-datepicker";
import CloseIcon from '@mui/icons-material/Close';
import * as sc from '../Service/read'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ExpandCircleDown } from "@mui/icons-material";
import tophsvg from '../../assets/img/BG.svg'

// import moment from "moment";
// import styled from "@emotion/styled";

// import EditIcon from '@mui/icons-material/Edit';
// import queryString from 'query-string'

const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
  ":hover": {
    boxShadow: theme.shadows[hovershadow],
  },
  boxShadow: theme.shadows[4],

}));

const headCells = [
  // { id: 'test_id', label: 'ID' },
  // { id: 'test_type', label: 'Type' },
  { id: 'test_case_description', label: 'Test Case Description' },
  { id: 'expected_result', label: 'Expected result' },
  { id: 'actual_result', label: 'Actual result' },
  { id: 'status', label: 'Status' },
  { id: 'started', label: 'Start date' },
  { id: 'ended', label: 'End date' },
  { id: 'actions', label: 'Actions', disableSorting: true }
]

const initialFValues = {
  id: '',
  test_scenario: 1,
  test_case_description: '',
  expected_result: '',
  actual_result: '',
  dev_spoc: '',
  tester_name: '',
  test_type: '',
  tool_name: '',
  severity_impact: '',
  started: '',
  ended: '',
  status: '',
  comments: ''
};

export const Testdetails = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const option = location.state.options;
  const project_name = location.state.options.label;
  const project_id = location.state.options.id;
  const [opts, setOpts] = React.useState(null);
  const [test_scenarioid, setTest_scenarioid] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null)
  const [recordForDelete, setRecordForDelete] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)
  const [records, setRecords] = useState([])
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'info' })
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [isDelete, setIsDelete] = useState(false)

  const handletestdetails = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {

    const access_token = localStorage.getItem('token');

    axios
      .get(
        "https://portal.squirrelvision.ai/api/v2/admin/test/scenario/read?project=" +
        project_id,
        {
          headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' }
        },

      )
      .then((res) => {
        console.log(1111111111111, res.data)
        const options = res.data.results.map((result) => ({
          labelscn: result.scenario_name,
          description: result.tester_name,
          id: result.id,
          name: result.project,
        }));

        setOpts(options);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    sc.getTestingData(setRecords)
  }, []);
  const addNewTest = (id) => {
    console.log("check", id)
    setTest_scenarioid(id);

    setOpenPopup(true);
    setRecordForEdit(null);
  }
  const editTest = (item, id) => {
    console.log("55555555555555555", item)
    setTest_scenarioid(id);

    setRecordForEdit(item)
    setOpenPopup(true);
  }
  const deleteTest = (item, id) => {
    // console.log("check")
    setTest_scenarioid(id);

    setRecordForDelete(item)
    setIsDelete(true);

  }
  const handleClose = () => {
    setRecordForEdit(null)
    setOpenPopup(false)
  };

  const addOrEdit = (Test, resetForm) => {
    const id = Test.test_id
    delete Test.test_id
    console.log("id", id);
    if (id == 0) {
      as.createTestCase(Test)
      sc.getTestingData(setRecords)
    }
    else {
      asx.updateTestCase(Test, id)
      sc.getTestingData(setRecords)
    }
    resetForm()
    setRecordForEdit(null)
    setOpenPopup(false)
  }
  const deletOnTest = () => {
    // adminServices.deleteService(recordForDelete)        
    // sc.getTestingData(setRecords) 
    setRecordForDelete(false)
    setIsDelete(false)
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  console.log(records)
  return (
    <>
      <SubHeader dropbutton />
      <Box sx={{ position: "fixed" }}>
       
       
       
      

       

       
          <Box
            sx={{
              background: `url(${tophsvg})`,
              position: "fixed",
              width: "100%",
              marginTop: 4,
              marginLeft: 3,

              height: "100%",
              overflow: "auto",
            }}
          >
             <Box
          sx={{ position: "fixed",  width: "94%" }}
        >
          <AppBar
            position="static"
            sx={{
              marginTop:10,
              marginLeft: 0.8,
              borderRadius: 3,
              marginBottom: "1.5%",
              backgroundColor: "white",
            }}
          >
            <Toolbar>
              <Typography
                className='MuiCardHeader-title'
                noWrap
                component="div"
                sx={{
                  display: { xs: "none", align: "left", sm: "block" },
                  color: "black",
                  fontSize: 20,
                }}
                display="inline"
              >
                Project details
              </Typography>
              {/* <Button
                variant="contained"
                sx={{
                  color: "black",
                  backgroundColor: "#d7a8df",
                  marginLeft: "auto",
                }}
              >
                Edit project
              </Button> */}
              {/* <EditIcon /> */}
            </Toolbar>
          </AppBar>
        </Box>
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            marginLeft: 0.1,
            marginTop:20
          }}
        >
          <Grid container spacing={4}>
            {/* {opts.map((option, index) => ( */}

            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ marginLeft: 3, color: "black", border: "20px" }}
              >
                Project name:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title2'
                noWrap
                component="div"
                sx={{ color: "black", fontWeight: "bold" }}
              >
                <Card sx={{ border: "1px" }}>{option.label}</Card>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ color: "black", fontSize: "15px" }}
              >
                Project owner:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title2'

                noWrap
                component="div"
                sx={{ color: "black", fontWeight: "bold", fontSize: "15px" }}
              >
                <Card>{option.owner}</Card>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ marginLeft: 3, color: "black" }}
              >
                Test lead:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title2'

                noWrap
                component="div"
                sx={{ color: "black" }}
              >
                <Card>{option.testlead}</Card>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ color: "black" }}
              >
                Test start date:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ color: "black", fontWeight: "bold", fontSize: "15px" }}
              >
                <Card>04/11/2023</Card>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ marginLeft: 3, color: "black" }}
              >
                Project description:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title2'

                noWrap
                component="div"
                sx={{ color: "black" }}
              >
                <Card>{option.projectdescription}</Card>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title1'
                noWrap
                component="div"
                sx={{ marginLeft: 0, color: "black" }}
              >
                Project Test Description:
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                className='MuiCardHeader-title2'

                noWrap
                component="div"
                sx={{ color: "black" }}
              >
                <Card>{option.projecttestdescription}</Card>
              </Typography>
            </Grid>
          </Grid>
        </Box>
             <Box sx={{ position: "fixed", width: "100%" }}>
          <Box
            sx={{
              position: "fixed",
              marginTop:41,
              width: "94%",
              marginLeft: 1,
            }}
          >
            <AppBar
              position="static"
              sx={{
                borderRadius: 3,
                marginBottom: "1.5%",
                backgroundColor: "white",
                
              }}
            >
              <Toolbar>
                <Typography
                  className='MuiCardHeader-title'
                  component="div"
                  sx={{
                    display: { xs: "none", align: "left", sm: "block" },
                    color: "black",
                  }}
                  display="inline"
                >
                  Scenarios
                </Typography>

                <Box sx={{  marginLeft: "auto" }}>
                  <Link
                    to="/newtestscenario"
                    state={{ opp: option }}
                    style={{ textDecoration: "none" }} // Add this line to remove underline
                  >
                    <Button
                      onClick={() => handletestdetails(option)}
                      variant="contained"
                      sx={{
                        color: "white",
                        backgroundColor: "#d7a8df",
                        marginLeft: "auto",

                      }}
                    >
                      <Typography className='MuiCardHeader-title3'
                      >Add scenario</Typography>
                    </Button>
                    {/* <EditIcon /> */}
                  </Link>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
        </Box>
        <Box mt={52}>
            {opts ? opts.map((option, index) => (
              <Box sx={{  padding: 1, marginRight: 10 }}>
                <StyledCard hovershadow={10}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        className='MuiCardHeader-title1'

                      >
                        {option.labelscn}
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container>
                        <Box
                          sx={{
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%"
                          }}
                        >

                          {
                            records ?

                              <Paper sx={{ width: "100%" }}>
                                <Toolbar>
                                  <Controls.Button
                                    sx={{
                                      color: "black",
                                      backgroundColor: "#d7a8df"
                                    }}
                                    text="Add New Testcase"
                                    className='MuiCardHeader-title3'

                                    startIcon={<AddIcon />}
                                    // onClick={addNewTest}
                                    onClick={() => addNewTest(option.id)}
                                  />
                                </Toolbar>
                                <TableContainer sx={{ width: "100%", overflowX: "auto", maxHeight: 440 }}>

                                  <TblContainer sx={{ width: '100%', maxWidth: '100%' }}>
                                    <TblHead />

                                    <TableBody>
                                      {
                                        recordsAfterPagingAndSorting().map((item, index) =>
                                        (
                                          option.id === item.test_scenario ?


                                            <TableRow key={index} className='MuiCardHeader-title3' test>

                                              {/* <TableCell>{item.test_id}</TableCell> */}
                                              {/* <TableCell>{item.test_type}</TableCell> */}
                                              <TableCell >{item.test_case_description}</TableCell>
                                              <TableCell>{item.expected_result}</TableCell>
                                              <TableCell>{item.actual_result}</TableCell>
                                              <TableCell>{item.status}</TableCell>
                                              <TableCell>{item.started}</TableCell>
                                              <TableCell>{item.ended}</TableCell>
                                              <TableCell>
                                                <Controls.ActionButton
                                                  color="primary"
                                                  onClick={() => { editTest(item, option.id) }}>
                                                  <EditOutlinedIcon fontSize="small" />
                                                </Controls.ActionButton>
                                                <Controls.ActionButton
                                                  color="secondary"
                                                  onClick={() => { deleteTest(item, option.id) }}
                                                >
                                                  <CloseIcon fontSize="small" />
                                                </Controls.ActionButton>
                                              </TableCell>
                                            </TableRow>
                                            : null
                                        ))
                                      }
                                    </TableBody>
                                  </TblContainer>
                                  <TblPagination />

                                </TableContainer>

                              </Paper>
                              :
                              "No data"
                          }


                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </StyledCard>
              
              </Box>
            )) : null}
          </Box>
          </Box>
        <Box sx={{ marginTop: 105 }}>
          
          <Button
            href="/Dashboard"
            sx={{
              color: "white",
              backgroundColor: "#d7a8df",
              marginLeft: 4,

            }}
          >
            <Typography className='MuiCardHeader-title3'
            >Back</Typography>
          </Button>
        </Box>
        <Dialog open={openPopup} onClose={handleClose}>
          <DialogTitle className='MuiCardHeader-title'>Test Info</DialogTitle>
          <DialogContent>
            <TestForm recordForEdit={recordForEdit}
              addOrEdit={addOrEdit}
              test_scenario_id={test_scenarioid} />
          </DialogContent>


        </Dialog>

     
      </Box>

    </>
  );
};
