import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import { FormControl } from '@mui/material';

export function useForm(initialFValues, validateOnChange = false, validate) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnChange) validate({ [name]: value });
  };
 

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

const StyledForm = styled('form')(({ theme }) => ({
    "& .MuiFormControl-root": {
      width: "100%",
      padding: 4,
      margin: theme.spacing(1),
    },
  }));
  
  export function Form(props) {
    const { children, ...other } = props;
    return (
      <StyledForm autoComplete="off" {...other}>
        {props.children}
      </StyledForm>
    );
  }