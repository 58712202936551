import { Autocomplete, Box, Button, Card, CardContent, Fab, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import React from "react";
import SubHeader from "../Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import TextField from '@mui/material/TextField';
import { Container } from "@mui/system";
// import { Link } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
// import queryString from 'query-string'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import EditIcon from "@mui/material/Edit";
import tophsvg from '../../assets/img/BG.svg'
import { useEffect } from "react";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BG from '../BG.svg'
import AddIcon from '@mui/icons-material/Add';
// import { Link } from 'react-router-dom';

export const Dashboardtest = () => {
  const [opts, setOpts] = React.useState([])
  const [data, setData] = React.useState([])

  const testss = [];
  useEffect(() => {
    const access_token = localStorage.getItem('token');

    axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/project/read', 
      {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' }
        },
    ).then((res) => {
      console.log(res);
      setData(res.data.results);
  
      const options = res.data.results.map((result) => ({
        label: result.project_name,
        id: result.project_id,
        description: result.project_description,
        owner: result.project_owner,
        testlead: result.test_lead,
        date: result.created_at,
        projectdescription: result.project_description,
        projecttestdescription: result.project_test_description

      }));
      console.log("Results",options )
      setOpts(options);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  

  const StyledCard = styled(Card)(({ theme, hovershadow }) => ({
    ":hover": {
      boxShadow: theme.shadows[hovershadow],
    },
    
    boxShadow: theme.shadows[4],

  }));

  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [searchClicked, setSearchClicked] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditMenuClick = (event) => {
    setEditAnchorEl(event.currentTarget);
  };

  const handleEditMenuClose = () => {
    setEditAnchorEl(null);
  };

  const handleDeleteMenuClick = (event) => {
    setDeleteAnchorEl(event.currentTarget);
  };

  const handleDeleteMenuClose = () => {
    setDeleteAnchorEl(null);
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const handletestdetails= (option)=>{
    setSelectedOption(option);
    console.log("id", option)
    console.log("Select", selectedOption)

  }



  const [opend, setOpend] = React.useState(false);
  const [values, setValues] = React.useState("");

  const [showGrid, setShowGrid] = useState(true);
  const navigate = useNavigate();
  const handleDelete = (event) => {
    const gridItem = event.target.parentNode.parentNode.parentNode;
    gridItem.remove();
  };

  if (!showGrid) {
    return null;
  }




        


//   const handleInputChange = (event, value) =>
//    { if (value === '') { setFilteredReports(reports); 
//   } 
//   else { setFilteredReports( reports.filter((rep) => rep.report_title.toLowerCase().includes(value.toLowerCase()) 
//     ) 
//     ); 
//   } 
// };


// useEffect(() => { if (selectedReportAuto === null) 
//   { setFilteredReports(reports);
//    } 
//    else { setFilteredReports( reports.filter((rep) => rep.report_title === selectedReportAuto.report_title)
//      ); 
//     } 
// }, [reports, selectedReportAuto]);



  return (
    <>
      <SubHeader dropbutton />

      <Box
>      
        {/* <Box sx={{ paddingLeft: 20, paddingRight: 20 }}> */}
<Box >
                    

<Box
sx={{
  background: `url(${tophsvg})`,
  // backgroundColor:'black',
  marginTop: 9,
  
  marginBottom: "1.5%",
  
  height: "100%",
  width: "100%",
 position: "fixed",
 top: 0,
left: 0
   
  
}}>
<Box sx={{ marginTop:4, flexGrow: 1, position: "fixed",  width: "97%" }}>


<AppBar
  position="static"
sx={{
   borderRadius: 3,
   marginBottom: "1.5%",
   backgroundColor:'#FFFFFF',
    marginLeft: 3
  }}
>
 <Toolbar>
    <Typography
    className='MuiCardHeader-title'
      
     noWrap
      component="div"
      sx={{ flexGrow: 1, color: "#616161", display: { xs: "none", sm: "block" } }}
   >
       Projects
   </Typography>







 
   
    <Button href="/newproject" sx={{  backgroundColor: "#d7a8df",color: "white", marginLeft:10 }} size='large'  variant="contained">
  <Typography className='MuiCardHeader-title3' sx={{color:'black'}}>Add project</Typography>   
     </Button>
     





 </Toolbar>
</AppBar>


</Box>


<Box  sx={{ zIndex:2}} mt={10} marginLeft={4} position='fixed'>

<div >
<Autocomplete 

disablePortal
id="combo-box-demo"

options={opts}

sx={{
width: 300,
backgroundColor: "#f2f2f2", // set background color to light gray
color: "blue",
marginTop:6 // set text color to blue
}}
renderInput={(params) => <TextField {...params}  className='MuiCardHeader-title1'  label='Search'/>}
// onInputChange={handleInputChange}
onChange={(event, newValue) => {


}}
/>


</div>

</Box>


  

<Box sx={{ marginTop:28, marginLeft:4}}>
<Grid container spacing={1}>
{opts.map((option, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Box >
        <StyledCard hovershadow={10}>
        <Card  sx={{  backgroundColor:'rgba(255, 255, 255, 0.9)', borderRadius: 2, width: '100%', height: '100%' }} className="org_card">
          <CardContent>
          <Stack  direction={"row"} >
            <Box>
            <Typography className='MuiCardHeader-title' color="#FF646E">
              {option.label}
            </Typography>
            </Box>
            <Box sx={{marginLeft:'auto'}} >
            
            <Link to="/test_details" state={{options: option}}>
           
  <Fab sx={{backgroundColor: "#FF646E"}} size="small" onClick={handleEditMenuClick} aria-label="add" className="dwnloadbtn">
    <Tooltip title='view' placement="right">
      <IconButton onClick={() => handletestdetails(option)} aria-label="Edit">
        <VisibilityOutlinedIcon />
      </IconButton>
    </Tooltip>
  </Fab>
  </Link>
</Box>


          
          </Stack>
         
            <Box sx={{marginTop:2, width:"100%"}}>
             
                <Typography className='MuiCardHeader-title1' color="text.secondary">
                  Description: <br/>
                  <Typography className='MuiCardHeader-title2'>
                  {option.description}
                </Typography>  
                </Typography>
            
          </Box>
          {/*  */}
        </CardContent>
      </Card>
      </StyledCard>
      </Box>
    </Grid>
            ))

           }
</Grid>
</Box>
</Box>




</Box>

      </Box>
    </>
  );
};
