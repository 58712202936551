let API_SERVER_VAL = '';

switch (process.env.NODE_ENV) {
    case 'development':
        // API_SERVER_VAL = 'https://127.0.0.1:8000';
        API_SERVER_VAL = 'https://portal.squirrelvision.ai';
        break;
    case 'production':
        // API_SERVER_VAL = "httpss:////portal.squirrelvision.ai";
        API_SERVER_VAL = 'https://portal.squirrelvision.ai';
        break;
    default:
        API_SERVER_VAL = 'https://portal.squirrelvision.ai';
        break;
}

export const API_SERVER = API_SERVER_VAL;

export const SESSION_DURATION = 5*3600*1000;