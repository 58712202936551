import React from "react";
import { connect } from "react-redux";
import {
  Box,
  TextField,
  Button,
  Paper,
  FormControl,
  Grid,
  Typography,
  Link,
  Divider,
  Card,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import Notification from "../../ui/Notifications";
import * as actions from "../../../store/authActions";

// import LoginImg from '../../assets/img/dates-fruit.png'
// import LoginImg from '../../assets/img/login.webp'
import LoginImg from "../../../assets/img/login.svg";
import Vector from "../../../assets/img/Vector.svg";
import logo from "../../../assets/img/Squirrel_logo_Final-01.png";
import { useLocation, useNavigate } from "react-router-dom";
import SQ from  '../../../assets/img/sqword.png'
import Saly1 from  '../../../assets/img/Saly-1.svg'
import Saly10 from  '../../../assets/img/Saly-10.svg'
import { Stack } from "@mui/system";



export const Login = (props) => {
  // Initialising storage
  const [username, setuserName] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "info",
  });



  let navigate = useNavigate();
  let location = useLocation();
  const loc = location.state
  if (location.pathname !== '/') {
    loc = { from: { pathname: location.pathname } }
  }
  console.log(123,loc)
  let { from } = loc || { from: { pathname: "/dashboard" } };
  React.useEffect(() => {
    if (props.isAuthenticated) { navigate(from) };
  });
  
  const theme = useTheme();
  const paperStyle = {
    
    height: "60vh",
    margin: "auto",
    color: "#355B3E",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgStyle = { width: "50px" };

  const textStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#355B3E',
      },
      '&:hover fieldset': {
        borderColor: '#355B3E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#355B3E',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#355B3E',
    },
  }

  const handleFormFieldChange = (event) => {
    switch (event.target.id) {
      case "email":
        setuserName(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      default:
        return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.onAuth(username, password, setNotify);
  };

  return (
    <>
      {!isMobile && (

        <Card sx={{ borderRadius: 2, padding: 1,backgroundColor:'rgba(127, 58, 117, 0.9)', width: '30%', height: '30%' }}>
        <Grid
          container
          spacing={2}
          // sx={{
          //   width: "50%",
          //   backgroundColor: "#fff",
          //   marginLeft: "10%",
          //   marginRight: "10%",
          //   borderRadius: "24px",
          //   padding: 0,
          // }}
        >
          <Grid item xs={12} sm={5} style={paperStyle}>

            
            {/* <Paper style={paperStyle}> */}
              <Box sx={{marginTop:10}}>
                <Grid container sx={{ display: "flex", marginBottom: 3 }}>
               <Stack direction={"row"} spacing={3}>
                <Grid item xs={2}>
                  <img src={logo} style={imgStyle}></img>
                </Grid>
                <Grid item xs={4}>
                  {/* <h2 sx={{ fontSize: 32 }} className="logo">
                    SQUIRREL
                </h2> */}
      <img src={SQ} alt="Squirrel" style={{width:'100px',marginTop:'32px'}}/>
                
                </Grid>
                </Stack>
              </Grid>
              </Box>
              {/* <Grid sx={{ marginBottom: 10 }}>
              </Grid> */}
              <Box>
              <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit} sx={{ marginTop: 5 }}>
                 <Box>
                
                  <Typography className="MuiCardHeader-title5">Username</Typography> 
                  <TextField
                    variant="outlined"
                    id="email"
                    
                    name="email"
                    autoComplete="username"
                    autoFocus
                    fullWidth
                    color="secondary"
                  onChange={handleFormFieldChange}
                  sx={textStyle}
                  />


<Typography marginTop={3} className="MuiCardHeader-title5">Password</Typography> 
                  <TextField
                    variant="outlined"
                    // margin="normal"
                    required
                    fullWidth
                    name="password"
                   
                    type="password"
                    id="password"
                    color="secondary"
                    autoComplete="current-password"
                  onChange={handleFormFieldChange}
                  sx={textStyle}
                  
                  />
                  
                  </Box>
                  <Button
                  sx={{marginTop:3}}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // className={classes.submit}
                  >
                    Sign In
                  </Button>
                  <Divider />

                </form>
              </Grid>
              </Box>
            {/* </Paper> */}
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={7}
            sx={{
              backgroundColor: "rgb(252 176 64 / 81%)",
              borderTopRightRadius: "24px",
              borderBottomRightRadius: "24px",
            }}
          > */}
            {/* <Paper sx={{ backgroundColor: "transparent", justifyContent: "center", alignContent: "center" }}> */}
            {/* <img
              sx={{
                maxWidth: "100%",
                height: "auto",
                resize: "auto",
              }}
              src={LoginImg}
            ></img> */}
            {/* </Paper> */}
          {/* </Grid> */}
        </Grid>
        </Card>
      )}
      {isMobile && (
        <>
        <Grid
            container
            style={{
              width: "100%",
              height: "10vh",
              background: "#fff",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={2}>
                  <img src={logo} style={imgStyle}></img>
                </Grid>
            <Grid item xs={4} >
                  {/* <h2 sx={{ fontSize: 32 }} className="logo">
                    SQUIRREL
                </h2> */}
      <img src={SQ} alt="Squirrel" style={{width:'100px',marginTop:'32px'}}/>
                
                </Grid>
          </Grid>
          <Grid
            container
            style={{
              width: "100%",
              height: "10vh",
              background: "transparent",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "white", fontSize: 24 }}>
              Login
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ width: "100%", height: "20vh", backgroundColor: "rgb(252 176 64 / 81%)",justifyContent: "center",
            alignItems: "center", }}
          >
             <img
              style={{
                maxWidth: "100%",        
    marginTop: "-26%",
                height: "auto",
                resize: "auto",
              }}
              src={Saly10}
            ></img>
          </Grid>
          de
          <Grid
            sx={{
              width: "100%",
              height: "60vh",
              backgroundColor: "#fff",
              padding:2,
              borderTopLeftRadius:6,
              borderTopRightRadius:6,
            }}
          >
                
             <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit} sx={{ marginTop: 5 }}>
                  <TextField
                    variant="outlined"
                    id="email"
                    label="User Name"
                    name="email"
                    autoComplete="username"
                    autoFocus
                    fullWidth
                    color="secondary"
                  onChange={handleFormFieldChange}
                  sx={textStyle}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    color="secondary"
                    autoComplete="current-password"
                  onChange={handleFormFieldChange}
                  sx={textStyle}
                  
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    // className={classes.submit}
                  >
                    Sign In
                  </Button>
                  <Divider />

                </form>
              </Grid>
          </Grid>
          
        </>
        
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password, setNotify) =>
      dispatch(actions.authLogin(username, password, setNotify)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
