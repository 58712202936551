import React from 'react'
import TextField from '@mui/material/TextField';

export default function InputTextArea(props) {

    const { name, label, required, value, error = null, onChange } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            required={required}
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            multiline
            maxRows={4}
            {...(error && {error:true,helperText:error})}
        />
    )
}