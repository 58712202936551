import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import Controls from '../../components/controls/Controls'
import { useForm, Form } from '../../components/useForm'


const initialFValues = {
    test_id: 0,
    test_scenario: 1,
    test_case_description: '',
    expected_result: 'NA',
    actual_result: 'NA',
    dev_spoc: '',
    tester_name: '',
    test_type: '',
    tool_name: '',
    severity_impact: '',
    started: '2023-01-01',
    ended: '2023-01-01',
    status: 'NA',
    comments: 'NA'
  }; 
export default function TestForm(props) {
    const { addOrEdit, recordForEdit, test_scenario_id } = props
//    alert(test_scenario_id)

  

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        // if ('test_scenario' in fieldValues)
        //   temp.test_scenario = fieldValues.test_scenario === 1 ? "This field is required." : "";
        // if ('test_case_description' in fieldValues)
        //   temp.test_case_description = fieldValues.test_case_description.length !== 0 ? "" : "This field is required.";
        setErrors({
          ...temp
        });
      
        if (fieldValues === values)
          return Object.values(temp).every(x => x === "");  
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    values.test_scenario=test_scenario_id
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])
console.log(77777777777777777777,recordForEdit)
    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={12}>
                {/* <Controls.Input
              name="test_id"
              label="ID"
              value={values.test_id}
              onChange={handleInputChange}
              error={errors.test_id}
                    /> */}
                    {/* <Controls.Input
              name="test_scenario"
              label="Test Scenario"
              required={true}
              value={test_scenario_id} 
              onChange={handleInputChange}
              error={errors.test_scenario}
              readOnly={true}
            /> */}
            <Controls.Input
              
              name="test_case_description"
              label="Test Case Description"
              value={values.test_case_description}
              onChange={handleInputChange}
              error={errors.test_case_description}
                    />
                    <Controls.InputTextArea
              name="expected_result"
              label="Expected Result"
              value={values.expected_result}
              onChange={handleInputChange}
              error={errors.expected_result}
            />
            <Controls.InputTextArea
              name="actual_result"
              label="Actual Result"
              value={values.actual_result}
              onChange={handleInputChange}
              error={errors.actual_result}
            />
            <Controls.Input
              name="dev_spoc"
              label="Dev SPOC"
              value={values.dev_spoc}
              onChange={handleInputChange}
              error={errors.dev_spoc}
            />
            <Controls.Input
              name="tester_name"
              label="Tester Name"
              value={values.tester_name}
              onChange={handleInputChange}
              error={errors.tester_name}
            />
            <Controls.Input
              name="test_type"
              label="Test Type"
              value={values.test_type}
              onChange={handleInputChange}
              error={errors.test_type}
            />
            <Controls.Input
              name="tool_name"
              label="Tool Name"
              value={values.tool_name}
              onChange={handleInputChange}
              error={errors.tool_name}
            />
            <Controls.Input
              name="severity_impact"
              label="Severity Impact"
              value={values.severity_impact}
              onChange={handleInputChange}
              error={errors.severity_impact}
                    />
                    <TextField
                                name="started"
                                label="Start Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.started}
                                // value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.started}                 
                    />
                 <TextField
                                name="ended"
                                label="End Date"
                                type="date"
                                        size="small"
                                        format="yyyy-MM-dd"
                                defaultValue={values.ended}
                                // value={values.purchaseDate}                                        
                                onChange={handleInputChange}                                        
                                InputLabelProps={{
                                shrink: true,
                                }}
                               error={errors.ended}                 
                            />
            {/* <Controls.DatePicker
              name="started"
              label="Started"
              value={values.started}
              onChange={handleInputChange}
              error={errors.started}
            />
            <Controls.DatePicker
              name="ended"
              label="Ended"
              value={values.ended}
              onChange={handleInputChange}
              error={errors.ended}
            /> */}
            <Controls.Input
              name="status"
              label="Status"
              value={values.status}
              onChange={handleInputChange}
              error={errors.status}
            />
            <Controls.InputTextArea
              name="comments"
              label="Comments"
              value={values.comments}
              onChange={handleInputChange}
              error={errors.comments}
            />
                    <div>
                        <Controls.Button
                            type="submit"
                            text="Submit"
                            color="secondary"
                        />
                        <Controls.Button
                            text="Reset"
                            color="info"
                            onClick={resetForm} />
                    </div>

                </Grid>

            </Grid>
        </Form>
    )
}
