import axios from "axios";
export function createProject(setRecords, data) {
    console.log("createProject called with data:", data);
    const access_token = localStorage.getItem('token');
    axios.post(`https://portal.squirrelvision.ai/api/v2/admin/test/project/create`, data, {
       

            headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },

       
    }).then(
            res => {

                // const data = res.data.results["created_by"]['email']
                console.log(res)
                // console.log(res.data)

                setRecords(res.data)
            })
    .catch(err => { console.log(err) })
}


export function createScenario(setRecords, data) {
    console.log("createScenario called with data:", data);
    // const token = localStorage.getItem('token');
    const access_token = localStorage.getItem('token');

    axios.post(`https://portal.squirrelvision.ai/api/v2/admin/test/scenario/create`, data, {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
       
    }).then(
        res => {

            // const data = res.data.results["created_by"]['email']
            console.log(res)
            // console.log(res.data)

            setRecords(res.data)
        })
        .catch(err => { console.log(err) })
}

export function createTestCase(data) {
    console.log("createTestCase called with data:", data);
    const access_token = localStorage.getItem('token');

    // const token = localStorage.getItem('token');
    axios.post(`https://portal.squirrelvision.ai/api/v2/admin/test/testing/create`, data, {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },

    }).then(
        res => {

            // const data = res.data.results["created_by"]['email']
            console.log(res)
            // console.log(res.data)

            // setRecords(res.data)
        })
        .catch(err => { console.log(err) })
}

