import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, Fab, Paper, Stack, tableCellClasses, TextareaAutosize, Tooltip } from "@mui/material";
import React from "react";
import SubHeader from "../Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState, useRef } from "react";
import TextField from '@mui/material/TextField';
import { Container } from "@mui/system";
import { Link } from "@mui/material";
import * as scene from "../Service/create";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarToday, Fullscreen, Label } from '@mui/icons-material';
import { IconButton } from "@mui/material";
// import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getProjectData } from "../Service/read";
import { useEffect } from "react";
import axios from 'axios';
// import { Flex } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import styled from "@emotion/styled";
// import Paper from '@mui/material/Paper';
// import { styled } from "@mui/material/styles";
// import { StyledTableCell } from './Table';
import * as sc from "../Service/read";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
// import { SaveIcon } from '@mui/icons-material'
// import {DeleteIcon, AddIcon, CancelIcon, SaveIcon, EditIcon} from '@mui/icons-material'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));



const initialRows = [{ id: 1, testId: "", testDescription: "", tStatus: "" }];
export const TestSc = () => {
  const [data, setData] = React.useState([])
  const [opts, setOpts] = React.useState([])
  const [values, setValues] = useState(null);
const [test_scenarioid, setTest_scenarioid]= useState(null);
  const testss = [];
  useEffect(() => {
    axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/scenario/read', {
      headers: {
        Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODA3Njc3NzcsInVzZXJfaWQiOjd9.fEyEVdChgT-UdJuwaz7N0lTn1xi3ZtS3uCmPgdCZj9c',
      },
    }).then((res) => {
      console.log(res);
      setData(res.data.results);
      console.log("scenarioname", res.data.results)
      console.log("scenario_name", res.data.results.scenario_name)
setTest_scenarioid(res.data.results.id)
      const options = res.data.results.map((result) => ({
        label: result.scenario_name,
        description: result.tester_name,
        id: result.id

      }));
      

      setOpts(options);
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  

  


  const [ttype, setTtype] = React.useState("");
  const [tname, setTname] = React.useState("");
  const [tid, setTid] = React.useState("");
  const [tdesc, setTdesc] = React.useState("");
  const [tstatus, setTdstatus] = React.useState("");
  const [expectedResult, setExpectedresult] = React.useState("");



  // const [testCases, setTestCases] = useState([]);

  const [open, setOpen] = React.useState(false);

  const [records, setRecords] = React.useState("");
  // const handleClose = () => {
  //   setOpen(false);
  // };





  const HandleSaveTestcase = () => {
    



    sc.getTestingData(setRecords, data);

    console.log("Data saved successfully!");
  };






  const [showTable, setShowTable] = useState(false);

  const [rows, setRows] = useState(initialRows);

  const [addedRows, setAddedRows] = useState([]);

const handleAddRow = () => {
  const newRow = {
    id: rows.length + 1,
    testId: "",
    testDescription: "",
  };
  setRows([...rows, newRow]);
  setAddedRows([...addedRows, newRow]); // update the addedRows state
};

  const handleSaveRow = (id, field, value) => {
    const newRowData = [...rows];
    const index = newRowData.findIndex((obj) => obj.id === id);
    newRowData[index] = {
      ...newRowData[index],
      [field]: value
    };
    setRows(newRowData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  
  const handleCreateTestcase = async (id) => {
    const Scenario=document.getElementById("scenarioname").value
const Testername=document.getElementById("testername").value
const Devspoc=document.getElementById("developerspoc").value
const Ttype=document.getElementById("testtype").value
const Tname=document.getElementById("toolname").value
const Tdescription=document.getElementById("testdesc").value
const Tidsevere=document.getElementById("severimpact").value
const ExpectedResult=document.getElementById("testExpectedResult").value
const ActualResult=document.getElementById("testActualResult").value
const Status=document.getElementById("tstats").value
const Comments=document.getElementById("comments").value
const Start=document.getElementById("start").value
const End=document.getElementById("end").value


const testCases = rows.map((row) => ({
  test_scenario: id,
  scenario_name: Scenario,
  tester_name: Testername,
  dev_spoc: Devspoc,
  test_type: Ttype,
  tool_name: Tname,
  test_case_description: rows.Tdescription,
  severity_impact: Tidsevere,
  expected_result: row.ExpectedResult,
  actual_result: row.ActualResult,
  status: row.tstats,
  comments: row.Comments,
  started: row.start,
  ended: row.end,
}));

// Make POST API call for each test case
testCases.forEach(async (testCase) => {
  await scene.createTestCase(null, testCase);
});
};




  
  







  return (
    <>
      <SubHeader dropbutton />

      <Box
        sx={{
          marginTop: "1.5%",
          marginLeft: "2%",
          marginBottom: "1.5%",
          marginRight: "1.5%",
          height: "100%",
        }}
      >
        {/* <Box sx={{ paddingLeft: 20, paddingRight: 20 }}> */}





        <Box
          sx={{
            marginTop: 20,
           
            marginBottom: "1.5%",
            marginRight: "1.5%",
            height: "50%",
            width: "100%",



          }}>
<Stack direction={"row"} spacing={250}>
            <Box sx={{marginBottom:1}}>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: "black", marginLeft: 2 }}
          >
            Scenarios
          </Typography>
          </Box>

          <Box sx={{marginBottom:3}}>
          <Button variant="contained" size="large">Add scenario</Button>
          </Box>
          </Stack>
          {opts.map((option, index) => (
            <Box sx={{ padding:2}}>
          <Accordion >

            <AccordionSummary

              expandIcon={<ExpandMoreIcon />}

              aria-controls="panel1bh-content"

              id="panel1bh-header"

            >



              <Typography sx={{ width: '33%', flexShrink: 0, fontSize: 20 }}>

              {option.label}

              </Typography>

              <Typography sx={{ color: 'text.secondary' }}></Typography>
              <Box sx={{marginLeft:'auto' }}> 
            <Stack  direction='row' spacing={2}>
            <Fab sx={{ backgroundColor: '#ff8080' }} size="small" aria-label="add" className="dwnloadbtn">
                  
                      <Tooltip title='edit' placement="left">
                        <IconButton aria-label="Edit">
                       
                          <ModeEditOutlineOutlinedIcon />
                        
                        </IconButton>
                      </Tooltip>
                  
                    </Fab>
                   

                    <Fab sx={{ backgroundColor: '#eef4f9' }} size="small" aria-label="add" className="dwnloadbtn">
                      <Tooltip title='delete'>
                        <IconButton aria-label="Edit">
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Fab>

                    <Fab sx={{ backgroundColor: '#abd0bc' }} size="small" aria-label="add" className="dwnloadbtn">
                      <Tooltip title='copy' >
                        <IconButton aria-label="Edit">
                          <CopyAllIcon />
                        </IconButton>
                      </Tooltip>
                    </Fab>


              </Stack>
              </Box>
            </AccordionSummary>

            <AccordionDetails>


              <Grid container>
                <Grid item xs={12} md={2}>

<TextField

  id="scenarioname"

  label="Secnario name"

  required={true}

  name="scenario_name"

  value={option.label}

// onChange={handleInputChange}

// error={errors.site_code}                                        

/>

</Grid>
<Grid item xs={12} md={2}>



                    <TextField

                      id="testername"

                      label="Tester name"

                      required={true}

                      name="tester_name"

                      value={option.description}

                    // onChange={handleInputChange}

                    // error={errors.location_code}                                        

                    />

                  </Grid>
<Grid item xs={12} md={2}>



<TextField

  id="developerspoc"

  label="Developer Spoc"

  required={true}

  name="dspoc"

  // value={option.description}

// onChange={handleInputChange}

// error={errors.location_code}                                        

/>

</Grid>

                  
                  <Grid item xs={12} md={2}>

<TextField

  id="testtype"

  label="Test type"

  required={true}

  name="test_type"

  // value={option.label}

// onChange={handleInputChange}

// error={errors.site_code}                                        

/>

</Grid>
                  <Grid item xs={12} md={2}>



                    <TextField

                      id="toolname"

                      label="Tool name"

                      required={true}

                      name="tool_name"

                      // value={option.description}

                    // onChange={handleInputChange}

                    // error={errors.location_code}                                        

                    />

                  </Grid>
                <Box sx={{ marginTop:2, display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Test Id</TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Test Description</TableCell>
                      {/* <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Test type</TableCell> */}
                      {/* <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Tool used</TableCell> */}
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Expected Result</TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Actual result</TableCell>
                      {/* <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Severity impact</TableCell> */}
                      {/* <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Developer SPOC</TableCell> */}
                      {/* <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Tester name</TableCell> */}
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Status</TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Comments</TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Start Date</TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>Date closed </TableCell>
                      <TableCell sx={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>
                        <Stack direction={"row"} spacing={1} >
                        <Fab  sx={{backgroundColor: '#abd0bc'}} size="small"  aria-label="add" className="dwnloadbtn">
                <Tooltip  title='add testcase' >
                  <IconButton onClick={handleAddRow} aria-label="Edit">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Fab>
             
                      
                   
                      <Fab  sx={{backgroundColor: '#abd0bc'}} size="small"  aria-label="add" className="dwnloadbtn">
                <Tooltip title='add testcase' >
                  <IconButton onClick={handleSaveRow} aria-label="Edit">
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </Fab>
              </Stack>
              </TableCell>
  


                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (

                      <StyledTableRow key={row.id}>
                        <StyledTableCell>
                          <TextareaAutosize
                          id="severimpact"
                            rowsMin={3}
                            value={option.description}
                            onChange={(e) =>
                            handleSaveRow(row.id, "testId", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                          id="testdesc"
                            rowsMin={3}
                            value={option.description}
                            onChange={(e) =>
                              handleSaveRow(row.id, "testDescription", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          <TextareaAutosize
                            rowsMin={3}
                            value={row.ttype}
                            onChange={(e) =>
                              handleSaveRow(row.id, "ttype", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell> */}
                        {/* <StyledTableCell>
                          <TextareaAutosize
                            rowsMin={3}
                            value={row.tname}
                            onChange={(e) =>
                              handleSaveRow(row.id, "tname", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell> */}
                        <StyledTableCell>
                          <TextareaAutosize
                          id="testExpectedResult"
                            rowsMin={3}
                            value={option.description}
                            onChange={(e) =>
                              handleSaveRow(row.id, "testExpectedResult", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <TextareaAutosize
                          id="testActualResult"
                            rowsMin={3}
                            // value={option.description}
                            // onChange={(e) =>
                            //   handleSaveRow(row.id, "testActualResult", e.target.value)
                            // }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          <TextareaAutosize
                            rowsMin={3}
                            value={row.severeImpact}
                            onChange={(e) =>
                              handleSaveRow(row.id, "severeimpact", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell> */}
                        {/* <StyledTableCell>
                          <TextareaAutosize
                            rowsMin={3}
                            value={row.dSpoc}
                            onChange={(e) =>
                              handleSaveRow(row.id, "devspoc", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell> */}
                        {/* <StyledTableCell>
                          <TextareaAutosize
                            rowsMin={3}
                            value={row.testername}
                            onChange={(e) =>
                              handleSaveRow(row.id, "testername", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell> */}


                        <StyledTableCell>
                          <TextareaAutosize
                          id="tstats"
                            rowsMin={3}
                            // value={row.tStatus}
                            onChange={(e) =>
                              handleSaveRow(row.id, "tstatus", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <TextareaAutosize
                          id="comments"
                            rowsMin={3}
                            // value={tComments}
                            onChange={(e) =>
                              handleSaveRow(row.id, "tcomments  ", e.target.value)
                            }
                            style={{ width: "100%", height: "50px" }}
                          />
                        </StyledTableCell>


                        <StyledTableCell>
                          <DatePicker
                          id="start"
                            selected={row.start ? new Date(row.start) : null}
                            onChange={(date) => handleSaveRow(row.id, "start", date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            style={{ width: "30%", height: "30px" }}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <DatePicker
                          id="end"
                            selected={row.end ? new Date(row.end) : null}
                            onChange={(date) => handleSaveRow(row.id, "end", date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                          // style={{ width: "200%", height: "50px" }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                         
                        </StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

<Button onClick={handleAddRow} size="large" variant="contained" sx={{ backgroundColor: "#d7a8df", marginTop: 1.5, minHeight: 53, borderRadius: 20, marginRight: 2 }}>
  <Typography sx={{ color: "black" }}>Add</Typography>
</Button>
<Button onClick={() => console.log(rows)} size="large" variant="contained" sx={{ backgroundColor: "#d7a8df", marginTop: 1.5, minHeight: 53, borderRadius: 20, marginRight: 2 }}>
  <Typography sx={{ color: "black" }}>Save</Typography>
</Button>
<Button onClick={()=> {handleCreateTestcase(option.id)}} size="large" variant="contained" sx={{ backgroundColor: "#d7a8df", marginTop: 1.5, minHeight: 53, borderRadius: 20, marginRight: 2 }}>
  <Typography sx={{ color: "black" }}>Save testcase</Typography>
</Button>
<Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Testcase added successfully"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Your testcase is saved successfully!
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button href="/dashboard" onClick={handleClose}>OK</Button>
  </DialogActions>
</Dialog>

{/* <Box sx={{ flex: '1' }}> */}
{/* Third box code */}

{/* </Flex> */}
</Box>
            </Box>
              </Grid>











            </AccordionDetails>

         
          </Accordion>
          </Box>
          ))}
          
           
         


      
    


</Box >




  

      </Box >
    </>
  );
}  