
import { Box, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import SubHeader from "../Testing/Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export const X = () => {
    return (
      
          <><SubHeader dropbutton />
          <Typography sx={{marginTop:20}}>

            Project details
        </Typography></>
                  
              
            
          
    
           
            
                  
    
               
              
              
       
      );
    };