import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import { drawerWidth } from  '../../../config/constant'; 
import { SET_MENU } from '../../../config/actions';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer'

// import bgheader from '../../../assets/img/headbg.png'
// import bgheader from '../../../Testing/Top1.svg'



const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
      }),
      [theme.breakpoints.up('md')]: {
          marginLeft: -(drawerWidth),
          width: `calc(100% - ${drawerWidth}px)`
      },
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px',
          width: `calc(100% - ${drawerWidth}px)`,
          padding: '10px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '8px',
          width: `calc(100% - ${drawerWidth}px)`,
          padding: '16px',
          marginRight: '8px'
      }
  }),
  ...(open && {
      transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down('md')]: {
          marginLeft: '20px'
      },
      [theme.breakpoints.down('sm')]: {
          marginLeft: '10px'
      }
  })
}));

export default function SquirrelLayout(props) {

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
      dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
      dispatch({ type: SET_MENU, opened: !matchDownMd });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);
  
  
  return (
    <><Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={3}
        sx={{
          // bgcolor: theme.palette.eathappy.eh_sweet_mint,'rgba(0, 0, 0, 0.2)',
          // backgroundImage: `url(${bgheader})`, backgroundRepeat: 'repeat', backgroundSize: 'contain',
          // backgroundColor: 'rgba(0, 0, 0, 0.1)',
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} {...props} />
        </Toolbar>
      </AppBar>

      <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} auth={props.auth} />
      <Main theme={theme} open={leftDrawerOpened} className="mainTheme">
        {props.children}
      </Main>

    </Box>
      <Footer handleLeftDrawerToggle={handleLeftDrawerToggle} /></>
  )
}
