import axios from 'axios';

export function getProjectData(setData) {
  const access_token = localStorage.getItem('token');

  axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/project/read', {
    headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
  }).then((res) => {
    console.log(res);
    setData(res.data.results);
    // return res.data
  }).catch((err) => {
    console.log(err);
  });
}


export function getScenarioData(setRecords) {
  const access_token = localStorage.getItem('token');

  axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/scenario/read', {
    headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
  }).then((res) => {
    console.log(res);
    setRecords(res.data);
  }).catch((err) => {
    console.log(err);
  });
}

export function getProjectId(setRecords, id) {
  const access_token = localStorage.getItem('token');

  axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/scenario/read?project='+id, {
    headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
  }).then((res) => {
    console.log(res);
    setRecords(res.data);
  }).catch((err) => {
    console.log(err);
  });
}



export function getTestingData(setRecords) {
  const access_token = localStorage.getItem('token');

  axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/testing/read', {
    headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
  }).then((res) => {
    console.log(res);
    setRecords(res.data.results);
    return res
  }).catch((err) => {
    console.log(err);
  });
}



export function getTestingDatas(setRecords,rows,setRows) {
  const access_token = localStorage.getItem('token');

  axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/testing/read', {
    headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
  }).then((res) => {
    console.log(res);
    setRecords(res.data.results);
    const dataset = res.data.results
    // if (records !== null) {
      console.log("result 12345",dataset)
    let newRow = []
      dataset.map((r, index) => { 
      newRow.push(
        {
          id: index,
          testId: r.id,
          testdesc: r.test_case_description,
          tstats: r.status,
          scenarioname: r.test_scenario,
          testername: r.tester_name,
          developerspoc: r.dev_spoc,
          testtype: r.test_type,
          toolname: r.tool_name,
          severimpact: r.severity_impact,
          testExpectedResult: r.expected_result,
          testActualResult: r.actual_result,
          comments: r.comments,
          start: r.started,
          end: r.ended
        })
        setRows(newRow);
    
    
    
      } )
    return res
  }).catch((err) => {
    console.log(err);
  });
}





