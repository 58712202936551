import { Autocomplete, Box, Button, Stack } from "@mui/material";
import React from "react";
import SubHeader from "../Layout/Subheader";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useState, useRef } from "react";
import TextField from '@mui/material/TextField';
import { Container } from "@mui/system";
import { Link } from "@mui/material";
import * as scene from "../Service/create";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarToday } from '@mui/icons-material';
import { IconButton } from "@mui/material";
// import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getProjectData } from "../Service/read";
import { useEffect } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import tophsvg from '../../assets/img/BG.svg'


// import queryString from 'query-string'



export const Newtestscenario = () => {
  const [data, setData] = React.useState([])
  const [opts, setOpts] = React.useState([])

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = getProjectData();
  //     setData(response.data);
  //   }

  //   fetchData();
  // }, []);


  const location = useLocation();
  console.log("lo", location)

  const option = location.state.opp
  const proj_name = option.label;
  const proj_id = option.id;

  console.log("Project Name", proj_name);
  console.log("Project ID", proj_id);
  // const project_name = location.state.options.label
  console.log("option", option)

  console.log("data", location.state.options)
  const testss = [];
  useEffect(() => {
    const access_token = localStorage.getItem('token');

    testss.length = 0;
    //  getProjectData(setData)
    axios.get('https://portal.squirrelvision.ai/api/v2/admin/test/project/read', {

      headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' }
    },
    ).then((res) => {
      console.log(res);
      setData(res.data.results);
      console.log("Response: ", res.data.results)
      console.log("Response 'data.results': ", res.data.results)

      res.data.results.forEach(i => {
        testss.push({ label: i.project_name, id: i.project_id })
      });
      setOpts(testss)
      // return res.data
    }).catch((err) => {
      console.log(err);
    });

  }, [])




  //text hover
  const [isHover1, setIsHover1] = useState(false);
  const [isHover2, setIsHover2] = useState(false);
  const [isHover3, setIsHover3] = useState(false);
  const [isHover4, setIsHover4] = useState(false);
  const [isHover5, setIsHover5] = useState(false);
  const [isHover6, setIsHover6] = useState(false);
  //icons hover
  const [card1, setCard1] = useState(false);
  const [card2, setCard2] = useState(false);
  const [card3, setCard3] = useState(false);
  const [card4, setCard4] = useState(false);
  const [card5, setCard5] = useState(false);
  const [card6, setCard6] = useState(false);







  const [open, setOpen] = React.useState(false);

  const handleSaveScenarioClick = () => {
    // Your code to save the scenario goes here
    setOpen(true); // Open the dialog box
  };

  // Handler function for closing the dialog box
  const handleClose = () => {
    setOpen(false);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(0);




  const [opend, setOpend] = React.useState(false);
  //   const [tdate, setTdate] = useState(null);

  const [records, setRecords] = React.useState("");

  const [tname, setTname] = React.useState("");
  // const [tdate, setTdate] = React.useState("");
  const [ttime, setTtime] = React.useState("");
  const [tscenario, setTscenario] = React.useState("");
  const [tscenariodesc, setTscenariodesc] = React.useState("");
  const [tprojectname, setTprojectname] = React.useState("");

  const [values, setValues] = React.useState("");



  const HandleSaveSceneClick = () => {
    var data = new Object()
    data["tester_name"] = tname
    // data["created_at"] = tdate
    data["expected_time"] = ttime
    data["scenario_name"] = tscenario
    data["test_result"] = tscenariodesc
    data["project_name"] = proj_name
    data["project"] = proj_id
    setOpen(true)
    console.log("Values", values)

    console.log("Add new scenario: ", data);
    // var response = createProject(data)
    scene.createScenario(setRecords, data);
    console.log("Call data", data);
  }




  return (
    <>
      <SubHeader dropbutton />

      <Box
        sx={{
          background: `url(${tophsvg})`,
          marginTop: "1.5%",
          marginBottom: "1.5%",
          height: "100%",
          paddingBottom: 30
        }}
      >
        {/* <Box sx={{ paddingLeft: 20, paddingRight: 20 }}> */}

        <AppBar
          position="static"
          sx={{
            marginTop: 14,
            borderRadius: 3,
            marginBottom: "0.5%",
            backgroundColor: "white",
            marginLeft: 4
          }}
        >
          <Toolbar>

            <Typography
              className='MuiCardHeader-title'
              component="div"
              sx={{ display: { xs: "none", align: 'left', sm: "block" }, color: "black" }}
              display="inline"

            >
              Test details
            </Typography>
            {/* <EditIcon /> */}

          </Toolbar>
        </AppBar>
        <Box sx={{ marginTop: 1, marginLeft: 6 }}>
          <Stack spacing={1} direction="row" sx={{ marginRight: 4, marginTop: 3, marginBottom: 4 }}>
          <Typography
              className='MuiCardHeader-title1'

              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" }, color: "black" }}
            >Project name :
            </Typography>
              <Typography className='MuiCardHeader-title2'> {option.label}
              </Typography>
            



          </Stack>


        </Box>



        <Box sx={{marginLeft:5, marginTop:2}}>
          <Box>

            <TextField value={tscenario} onChange={(e) => { setTscenario(e.target.value) }} id="t_name" label="Test scenario" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
          </Box>
          <Box>

            <TextField value={tscenariodesc} onChange={(e) => { setTscenariodesc(e.target.value) }} id="test_scenario" label="Test scenario description" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5, }} multiline
              rows={4} />
          </Box>
          <Box>

            <TextField value={ttime} onChange={(e) => { setTtime(e.target.value) }} id="t_time" label="Expected time" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
          </Box>

          <Box>

            <TextField value={tname} onChange={(e) => { setTname(e.target.value) }} id="t_time" label="Tester Name" variant="outlined" sx={{ minWidth: 600, marginBottom: 1.5, marginTop: 1.5 }} />
          </Box>




          {/* <Link href="/Testcases" underline="none"> */}
          {/* <Link href="/Dashboard"> */}
          <div>
            <Button onClick={HandleSaveSceneClick} size="large" variant="contained" sx={{ backgroundColor: "#d7a8df", marginTop: 1.5, minHeight: 53, borderRadius: 20, marginRight: 2 }}>
              <Typography className='MuiCardHeader-title3' sx={{ color: "black" }}>Save scenario</Typography>
            </Button>
            <Dialog  open={open} onClose={handleClose}>
              <DialogTitle className='MuiCardHeader-title1'>Scenario saved successfully!</DialogTitle>
              <DialogContent>
                <DialogContentText className='MuiCardHeader-title3'>
                  Your scenario has been saved successfully.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button href="/dashboard" onClick={handleClose}>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Box>

        {/* <Button onClick={() => HandleAddProjectClick()} size="large" variant="contained" sx={{ backgroundColor: "#d7a8df", marginTop: 1.5, minHeight: 53, borderRadius: 20 }}>
          <Typography sx={{ color: "black" }}>Cancel​</Typography>
        </Button> */}
        {/* </Link> */}
        {/* </Link> */}





      </Box>

    </>
  );
};
