import axios from 'axios'

export function updateProject(setRecords, data, id) {
    const access_token = localStorage.getItem('token');

    console.log("updateTestCase called with data:", data);
    axios.put(`https://portal.squirrelvision.ai/api/v2/admin/test/project/update/${id}`, data, {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
    }).then(
        res => {
            console.log(res)
            setRecords(res.data)
        })
        .catch(err => { console.log(err) })
}


export function updateScenario(setRecords, data, id) {
    const access_token = localStorage.getItem('token');

    console.log("updateTestCase called with data:", data);
    axios.put(`https://portal.squirrelvision.ai/api/v2/admin/test/scenario/update/${id}`, data, {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
    }).then(
        res => {
            console.log(res)
            setRecords(res.data)
        })
        .catch(err => { console.log(err) })
}



export function updateTestCase(data, id) {
    const access_token = localStorage.getItem('token');

    console.log("updateTestcase called with data:", data);
    console.log("updateTestCase called with id:", id);
    axios.put(`https://portal.squirrelvision.ai/api/v2/admin/test/testing/update/${id}`, data, {
        headers: { Authorization: `Bearer ${access_token}`, 'Content-Type': 'application/json' },
    }).then(
        res => {
            console.log(res)
            // setRecords(res.data)
        })
        .catch(err => { console.log(err) })
}
