// assets
import {
  EventAvailableOutlined,
  DataArrayOutlined,
  NoteAddOutlined,
} from "@mui/icons-material";
import SatelliteAltOutlinedIcon from "@mui/icons-material/SatelliteAltOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LabelImportantOutlinedIcon from "@mui/icons-material/LabelImportantOutlined";
import ThreeDRotationOutlinedIcon from '@mui/icons-material/ThreeDRotationOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
// constant
const icons = {
  EventAvailableOutlined,
  GroupAddOutlinedIcon,
  DataArrayOutlined,
  NoteAddOutlined,
  SatelliteAltOutlinedIcon,
  PaymentsOutlinedIcon,
  MiscellaneousServicesIcon,
  WorkHistoryOutlinedIcon,
    HandshakeOutlinedIcon,
    LabelImportantOutlinedIcon,
    ThreeDRotationOutlinedIcon,
  BugReportOutlinedIcon,
  AccountTreeOutlinedIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
  id: "admin-menu",
  title: "Admin",
  type: "group",
  permission: ["admin"],
  children: [
    {
      id: "event_hub",
      title: "Event Hub",
      type: "item",
      url: "https://akhq.difinative.com/ui/my-cluster-plain-text/topic",
      icon: icons.EventAvailableOutlined,
      external: true,
      target: true,
    },
    {
      id: "teleport",
      title: "Teleport",
      type: "item",
      url: "httpss://fcftport.northeurope.cloudapp.azure.com/web/login?redirect_uri=httpss://fcftport.northeurope.cloudapp.azure.com/web/cluster/fcftport.northeurope.cloudapp.azure.com/nodes",
      icon: icons.SatelliteAltOutlinedIcon,
      external: true,
      target: true,
    },
    {
      id: "MlOps",
      title: "MlOps",
      type: "item",
      url: "#",
      icon: icons.LabelImportantOutlinedIcon,
      external: true,
      target: true,
      },
      {
        id: "MetaVerse",
        title: "MetaVerse",
        type: "item",
        url: "https://3.122.184.47:8082/",
        icon: icons.ThreeDRotationOutlinedIcon,
        external: true,
        target: true,
    },
    {
      id: "SmartLogic",
      title: "Smart Logic",
      type: "item",
      url: "httpss://nodered.beta.dev.difinative.com:8030",
      icon: icons.AccountTreeOutlinedIcon,
      external: true,
      target: true,
    },
    {
      id: "ImageProcess",
      title: "Image Process",
      type: "item",
      url: " httpss://squirrel-imgprocess-ui.difinative.com/",
      icon: icons.AccountTreeOutlinedIcon,
      external: true,
      target: true,
    },
    {
      id: "monitoring",
      title: "Smart Monitoring",
      type: "item",
      url: "httpss://grafana.squirrelvision.ai",
      icon: icons.AccountTreeOutlinedIcon,
      external: true,
      target: true,
    },
      {
        id: "SqAssurence",
        title: "SqAssurence",
        type: "item",
        url: "https://sqtest.difinative.com:8855",
        icon: icons.BugReportOutlinedIcon,
        external: true,
        target: true,
      },
    {
      id: "users",
      title: "User Management",
      type: "item",
      url: "/admin-panel/users",
      icon: icons.GroupAddOutlinedIcon,
    },
    {
      id: "bills",
      title: "Bills",
      type: "item",
      url: "/admin-panel/bills",
      icon: icons.PaymentsOutlinedIcon,
    },
    {
      id: "service",
      title: "Services",
      type: "item",
      url: "/admin-panel/services",
      icon: icons.MiscellaneousServicesIcon,
    },
    {
      id: "partner",
      title: "Partners",
      type: "item",
      url: "/admin-panel/partners",
      icon: icons.WorkHistoryOutlinedIcon,
    },
    {
      id: "contract",
      title: "Contracts",
      type: "item",
      url: "/admin-panel/contracts",
      icon: icons.HandshakeOutlinedIcon,
    },
    {
      id: "onboard",
      title: "Onboard",
      type: "collapse",
      icon: icons.NoteAddOutlined,
      children: [

        {
          id: "location_compute",
          title: "Location Compute",
          type: "item",
          url: "/admin-panel/onboard/location-compute",
          breadcrumbs: false,
        },
        {
          id: "asset_compute",
          title: "Asset Compute",
          type: "item",
          url: "/admin-panel/onboard/asset-compute",
          breadcrumbs: false,
        },
        {
          id: "incident_categories",
          title: "Incident Category",
          type: "item",
          url: "/admin-panel/onboard/incident-categories",
          breadcrumbs: false,
        },
        {
          id: "store_categories",
          title: "Store Categories",
          type: "item",
          url: "/admin-panel/onboard/store-categories",
          breadcrumbs: false,
        },
        {
          id: "catalogue_categories",
          title: "Catalogue Categories",
          type: "item",
          url: "/admin-panel/onboard/catalogue-categories",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default admin;
